import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomArrow } from "../../Carousel/Carousel";
import { GalleryCard, IGalleryProps, IRenderCardProps } from "../Contracts/contracts";
import "../Style/style.scss";

const Card: React.FC<IRenderCardProps> = (props) => {
	return (
		<div className="gallery-card-wrapper">
			<div className="gallery-card">
				<img className="gallery-image" src={props.card.ImageUrl} />
			</div>
			{!props.hideCaption && props.card?.Caption && (
				<p id={props.galleryId} className="gallery-card-text">
					{props.card?.Caption}
				</p>
			)}
		</div>
	);
};

export const Gallery = (props: IGalleryProps) => {
	const [currentCard, setCurrentCard] = useState<GalleryCard | undefined>(undefined);
	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		const keyPressHandler = (e) => {
			if (showModal) {
				if (e.keyCode === 37) {
					setCurrentCard((prev) => {
						const newCurrentCard = props.cards.find((card) => card.Id === prev.Id - 1);
						return newCurrentCard ? newCurrentCard : prev;
					});
				}
				if (e.keyCode === 39) {
					setCurrentCard((prev) => {
						const newCurrentCard = props.cards.find((card) => card.Id === prev.Id + 1);
						return newCurrentCard ? newCurrentCard : prev;
					});
				}
			}
		};

		window.addEventListener("keydown", keyPressHandler);

		return () => {
			window.removeEventListener("keydown", keyPressHandler);
		};
	}, [setCurrentCard, props.cards, showModal]);

	return (
		<div className="gallery">
			{props.cards.map((card: GalleryCard, i: number) => (
				<div
					className="gallery-card-wrapper"
					onClick={() => {
						setShowModal(true);
						setCurrentCard(card);
					}}
					key={`${props.galleryId}-card-${i}}`}
				>
					<Card card={card} hideCaption galleryId={props.galleryId} />
				</div>
			))}
			<Modal
				className="gallery-modal"
				show={showModal}
				onHide={() => setShowModal(false)}
				centered
				size="xl"
			>
				<Modal.Header closeButton />
				<Modal.Body id={props.galleryId} className="gallery-modal-body">
					<CustomArrow
						onClick={() =>
							setCurrentCard((prev) => {
								const newCurrentCard = props.cards.find(
									(card) => card.Id === prev.Id - 1
								);
								return newCurrentCard ? newCurrentCard : prev;
							})
						}
						direction="left"
					/>
					<Card card={currentCard} galleryId={props.galleryId} />
					<CustomArrow
						onClick={() =>
							setCurrentCard((prev) => {
								const newCurrentCard = props.cards.find(
									(card) => card.Id === prev.Id + 1
								);
								return newCurrentCard ? newCurrentCard : prev;
							})
						}
						direction="right"
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};
